.swing-in-top-bck {
  -webkit-animation: swing-in-top-bck 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-bck 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


.example-enter {
  opacity: 10;
  animation: swing-in-top-bck 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.example-enter.example-enter-active {
  opacity: 10;
  transition: opacity 500ms ease-in;
  animation: swing-in-top-bck 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.5;
  // -webkit-animation: slit-out-horizontal 0.5s ease-in both;
  animation: slit-out-horizontal 0.5s ease-in both;
  transition: opacity 500ms ease-in;

}

.example-appear {
  opacity: 0.5;
}

.example-appear.example-appear-active {

  animation: swing-in-top-bck 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-8-8 21:57:33
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-bck
 * ----------------------------------------
 */

@-webkit-keyframes swing-in-top-bck {
  0% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2018-10-10 21:39:10
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-out-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
    transform: translateZ(0) rotateX(0);
    opacity: 1;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
    transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
    transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
    transform: translateZ(0) rotateX(0);
    opacity: 1;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
    transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
    transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}