@import "partials/_text";
@import "partials/_sitepages";
@import "partials/_header";

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), format("woff2");
  font-display: swap;
}

input[type="submit"]:disabled {
  background: #dddddd;
  color: #898a88;
}

// Menu toggle bar icon

#nav-icon2 {
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  width: 50px;
  height: 50px;
  position: relative;

  span {
    height: 9px;
    width: 50%;
    display: block;
    position: absolute;
    height: 9px;
    width: 50%;
    background: #d3531a;
    opacity: 1;

    transform: rotate(20deg);

    transition: 0.25s ease-in-out;
  }
}
