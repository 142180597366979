$slide_enter_time: 5;

.slide-in-fwd-top {
  -webkit-animation: slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slider-enter {
  opacity: 0;
}
.slider-enter-active {
  opacity: 1;
  -webkit-animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.slider-exit {
  opacity: 1;
}

.slider-exit-active {
  opacity: 0;
  -webkit-animation: tracking-out-expand 4ms cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-out-expand 4ms cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-30 22:0:1
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-30 21:26:31
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */

@-webkit-keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 21:31:32
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-top
 * ----------------------------------------
 */

@keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px);
    transform: translateZ(-1400px) translateY(-800px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
